import SEO from '../components/core/meta/SEO';
import { Container } from '../components/core/layout/Layout';
import Heading from '../components/core/typography/Heading';
import React from 'react';
import 'twin.macro';
import { StyledGeneratedHtmlContainer } from './privacy-e-cookie-policy';

const PrivacyPolicyPage = () => {
  return (
    <StyledGeneratedHtmlContainer tw="py-16">
      <SEO title="Privacy policy" url="/privacy-policy" />
      <section tw="mb-16">
        <Container>
          <Heading variant="h1" tw="text-primary-500 mb-0 text-5xl">
            PRIVACY POLICY
          </Heading>
          <div>
            <p>
              <em>relativa al sito internet </em>
              <a href="http://www.hellougo.com">
                <em>www.hellougo.com</em>
              </a>
              <em> </em>
            </p>
            <p>
              Gentile <strong>Utente</strong>,
            </p>
            <p>
              sicuramente avrai sentito parlare del Regolamento Europeo n.
              679/2016 (meglio noto come “<strong>GDPR</strong>”) in materia di
              protezione dei dati personali e tutela delle persone fisiche.
            </p>
            <p>
              Ecco, proprio nel rispetto tale normativa e quella nazionale
              vigente, di seguito ti forniamo le informazioni necessarie a
              comprendere come trattiamo i dati da te forniti mediante
              l’utilizzo della sito internet
              <a href="http://www.hellougo.com">www.hellougo.com</a> di
              proprietà della UGO Pnp S.r.l.
            </p>
            <p>
              Ti invitiamo a leggere questa pagina come se fossi tu a porci le
              domande riportate nei paragrafi che seguono e noi a fornirti le
              risposte correlate: se avessi ulteriori dubbi, non esitare a
              contattarci, siamo qui per<strong> </strong>te. Tu
              <strong> </strong>sei il soggetto da tutelare e noi vogliamo
              mostrarti la nostra trasparenza nel farlo.
            </p>
            <p>Cominciamo!</p>
            <table>
              <tr>
                <td>
                  <h2>CHI È IL TITOLARE DEL TRATTAMENTO?</h2>
                </td>
                <td>
                  <p>
                    <strong>UGO pnp S.r.l. </strong>– via Castaldi n. 29, 20124,
                    Milano (MI)
                  </p>
                  <p>
                    <strong>P.Iva e C.f.</strong>: 08979490961
                  </p>
                  <p>
                    <strong>e-mail: </strong>
                    <a href="mailto:info@hellougo.com">info@hellougo.com</a>
                  </p>
                </td>
              </tr>
            </table>
            <p>
              La <strong>Ugo pnp S.r.l.</strong> (di seguito “
              <strong>UGO</strong>” e/o “<strong>Titolare</strong>”) è il
              titolare del trattamento dei dati personali degli Utenti (di
              seguito anche “<strong>Interessati</strong>”) che navigano ed
              interagiscono sul sito internet
              <a href="http://www.hellougo.com">www.hellougo.com</a> (di seguito
              “<strong>Sito</strong>”), inclusi i sottodomini.
            </p>
            <table>
              <tr>
                <td>
                  <h2>COME POSSO CONTATTARE IL DPO?</h2>
                </td>
                <td>
                  <p>
                    <strong>e-mail</strong>:{' '}
                    <a href="mailto:privacy@hellougo.com">
                      privacy@hellougo.com
                    </a>
                  </p>
                </td>
              </tr>
            </table>
            <p>
              Mediante il Sito, UGO fornisce un insieme di “
              <strong>Servizi</strong>” assistenziali all’Utente. Per scoprirne
              di più, Ti invitiamo a leggere i nostri Termini e Condizioni.
            </p>
            <table>
              <tr>
                <td>
                  <p>
                    <strong>QUALI DATI TRATTIAMO ATTRAVERSO IL SITO?</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Dati raccolti durante la <strong>navigazione</strong> del
                    Sito:
                  </p>
                  <ul>
                    <li>
                      <ul>
                        <li>
                          Indirizzo IP, url, browser utilizzato, metadati e
                          cookie (questi ultimi in conformità alla nostra cookie
                          policy);
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p>
                    Dati raccolti mediante il form “
                    <strong>calcola il tuo preventivo</strong>”:
                  </p>
                  <ul>
                    <li>
                      <ul>
                        <li>per quale soggetto è richiesto il servizio;</li>
                        <li>
                          data e ora di inizio e fine del servizio richiesto;
                        </li>
                        <li>durata totale stimata (facoltativo);</li>
                        <li>indirizzo di partenza e di arrivo del servizio;</li>
                        <li>
                          eventuali convenzioni attive o codice promozionale
                          (facoltativo);
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p>
                    Dati raccolti mediante il form di{' '}
                    <strong>richiesta e/o prenotazione di un servizio</strong>:
                  </p>
                  <ul>
                    <li>
                      <ul>
                        <li>nome e cognome;</li>
                        <li>
                          telefono e indirizzo e-mail (quest’ultimo
                          facoltativo);
                        </li>
                        <li>
                          città di domicilio/residenza (tra quelle indicate ove
                          è attivo il servizio);
                        </li>
                        <li>servizio scelto (tra quelli indicati);</li>
                        <li>punto vendita scelto dall’utente;</li>
                        <li>
                          età e per quale soggetto è richiesto il servizio;
                        </li>
                        <li>
                          indirizzo di domicilio completo o luogo di consegna
                          (in base al servizio scelto);
                        </li>
                        <li>data scelta per l’esecuzione del servizio;</li>
                        <li>
                          fascia oraria preferibile per il servizio e metodo di
                          pagamento;
                        </li>
                        <li>
                          autonomia nella compilazione della richiesta e
                          modalità con cui è stato conosciuto UGO;
                        </li>
                        <li>
                          informazioni aggiuntive dell’Utente (facoltativo e
                          mediante campo libero all’interno del form);
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <p>
                    Dati raccolti durante la{' '}
                    <strong>
                      registrazione e creazione dell’account Utente
                    </strong>
                    :
                  </p>
                  <ul>
                    <li>
                      <ul>
                        <li>nome e cognome;</li>
                        <li>indirizzo e-mail e telefono;</li>
                        <li>password;</li>
                        <li>
                          modalità con cui è stato conosciuto UGO (facoltativo).
                        </li>
                      </ul>
                    </li>
                  </ul>
                </td>
              </tr>
            </table>
            <p>
              Nonostante UGO non abbia la necessità di trattare ulteriori dati
              rispetto a quelli sopra esposti, le informazioni ulteriori che
              l’Interessato fornisce mediante l’utilizzo del Sito ed i form in
              esso presenti (a titolo esemplificativo e non esaustivo, ci si
              riferisce agli spazi di “campo libero” presenti all’interno del
              Sito ed ove l’Utente ha libertà di espressione ed inserimento
              dati, quali e-mail per richiesta di informazioni e/o assistenza)
              saranno trattati in conformità della presente Privacy Policy, dei
              Termini e Condizioni e nel pieno rispetto di quanto previsto dalla
              normativa in materia (di seguito i dati personali e dati
              particolari saranno congiuntamente richiamati e definiti come “
              <strong>Dati</strong>”).
            </p>
            <table>
              <tr>
                <td>
                  <h2>PER QUALI FINALITÀ SONO TRATTATI I DATI?</h2>
                </td>
                <td>
                  <h2>SU QUALE BASE GIURIDICA VENGONO TRATTATI?</h2>
                </td>
                <td>
                  <h2>PER QUANTO TEMPO VENGONO CONSERVATI?</h2>
                </td>
              </tr>
              <tr>
                <td>
                  <ol>
                    <li>
                      <strong>
                        Consentire l’accesso e il corretto funzionamento del
                        Sito
                      </strong>
                    </li>
                  </ol>
                </td>
                <td>
                  <p>
                    Legittimo interesse del Titolare a fornire i Servizi tramite
                    il Sito
                  </p>
                </td>
                <td>
                  <p>Vedi la nostra Cookie Policy per il Sito</p>
                </td>
              </tr>
              <tr>
                <td>
                  <ol>
                    <li>
                      <strong>
                        Riscontrare le richieste di informazioni e le richieste
                        relative ai Servizi
                      </strong>
                    </li>
                  </ol>
                </td>
                <td>
                  <p>
                    Legittimo interesse del Titolare a rispondere ad eventuali
                    richieste degli Utenti
                  </p>
                </td>
                <td>
                  <p>
                    I Dati saranno conservati per la durata strettamente
                    necessaria all’esecuzione della richiesta
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <ol>
                    <li>
                      <strong>
                        Instaurare il rapporto contrattuale relativo ai Servizi
                        e adempiere alle obbligazioni contrattuali nei confronti
                        degli Utenti (finalità contrattuali)
                      </strong>
                    </li>
                  </ol>
                </td>
                <td>
                  <p>
                    Adempimento e gestione contrattuale per erogazione dei
                    Servizi offerti. Il conferimento dei Dati è necessario per
                    la corretta erogazione dei Servizi
                  </p>
                </td>
                <td rowSpan="2">
                  <p>
                    I Dati saranno conservati per un periodo pari alla durata
                    del contratto (inclusi eventuali rinnovi) e per i successivi
                    10 anni (per ciò che concerne dati fiscali, di pagamento e
                    fatturazione, salvo diversi termini previsti in conformità
                    alla normativa in materia)
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <ol>
                    <li>
                      <strong>
                        Adempiere gli obblighi amministrativi e contabili
                        previsti dalla normativa (ad esempio in materia di
                        antiriciclaggio, gestione della contabilità e
                        fatturazione)
                      </strong>
                    </li>
                  </ol>
                </td>
                <td>
                  <p>
                    Legittimo interesse del Titolare ad adempiere gli obblighi
                    di legge
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <ol>
                    <li>
                      <strong>
                        Svolgere attività di statistica sugli Utenti{' '}
                      </strong>
                    </li>
                  </ol>
                </td>
                <td>
                  <p>
                    Consenso. Il consenso è facoltativo e revocabile in
                    qualsiasi momento
                  </p>
                </td>
                <td>
                  <p>
                    I Dati saranno opportunamente resi anonimi e comunicati
                    unicamente in forma aggregata
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <ol>
                    <li>
                      <strong>
                        Per la promozione di Prodotti e Servizi sia tramite
                        strumenti di comunicazione tradizionale che tramite
                        strumenti di comunicazione a distanza (finalità di
                        marketing, ad es. tramite Newsletter)
                      </strong>
                    </li>
                  </ol>
                </td>
                <td>
                  <p>
                    Consenso. Il consenso è facoltativo e revocabile in
                    qualsiasi momento
                  </p>
                </td>
                <td>
                  <p>
                    Periodo massimo pari a 24 mesi dall’espressione del consenso
                    da parte dell’Interessato e in conformità con la nostra
                    Cookie Policy
                  </p>
                </td>
              </tr>
            </table>
            <p>
              I Dati sono trattati con strumenti manuali o informatici, anche
              mediante strumenti automatizzati, idonei a garantirne la
              confidenzialità, l'integrità e la riservatezza dei Dati stessi nel
              rispetto delle misure tecniche e idonee in conformità con la
              normativa vigente.
            </p>
            <p>
              L’archiviazione informativa dei Dati è effettuata mediante
              strumenti di cloud computing su server localizzati all’interno del
              territorio dell’UE (Germania): per maggiori informazioni sugli
              standard di sicurezza, compliance e conformità ai requirements
              richiesti dalla normativa comunitaria e nazionale vigente in
              materia ed adottati dai provider esterni scelti, si invita la
              consultazione della
              <a href="https://www.digitalocean.com/legal/privacy-policy">
                privacy policy del provider.
              </a>
            </p>
            <p>
              Decorsi i termini di conservazione sopra indicati, i Dati saranno
              distrutti, cancellati o resi anonimi, compatibilmente con le
              procedure tecniche di cancellazione e backup.
            </p>
            <table>
              <tr>
                <td>
                  <p>
                    <strong>A CHI VENGONO COMUNICATI I DATI?</strong>
                  </p>
                </td>
                <td>
                  <ul>
                    <li>
                      Fornitori di servizi connessi alle attività del Titolare
                      del trattamento ed istituzioni pubbliche
                    </li>
                    <li>
                      Fornitori di servizi di assistenza, consulenza fiscale e
                      legale
                    </li>
                    <li>
                      Fornitori di servizi informatici o di archiviazione e
                      provider di servizi esterni, tra cui, a titolo
                      esemplificativo e non esaustivo, Typeform S.L. (Spagna),
                      la cui privacy policy è consultabile
                      <a href="https://admin.typeform.com/to/dwk6gt?typeform-source=duckduckgo.com">
                        qui
                      </a>
                      .
                    </li>
                  </ul>
                </td>
              </tr>
            </table>
            <p>
              <a id="_Hlk63772616"></a>I Dati resi mediante il Sito saranno
              trattati unicamente per garantire la corretta fruizione dei
              Servizi e potranno essere visionati ed utilizzati dal team di UGO
              (dipendenti e collaboratori) opportunamente autorizzato al
              trattamento.
            </p>
            <p>
              I Dati potrebbero essere comunicati inoltre per finalità di
              legittimo interesse per lo svolgimento di attività funzionali a
              eventuali cartolarizzazioni, cessioni del credito ed emissione di
              titoli, cessioni di azienda e di ramo d'azienda, acquisizioni,
              fusioni, scissioni o altre trasformazioni e per l'esecuzione di
              tali operazioni.
            </p>
            <p>
              I soggetti sopra indicati potranno agire, a seconda dei casi, in
              qualità di responsabili esterni del trattamento o autonomi
              titolari del trattamento in conformità con la normativa vigente.
              Potrai richiedere l’elenco aggiornato delle società a cui saranno
              comunicati i Dati in qualsiasi momento al Titolare, mediante
              specifica richiesta da inviare utilizzando i canali di contatto
              indicati nella presente Privacy Policy.
            </p>
            <table>
              <tr>
                <td>
                  <p>
                    <strong>I DATI SONO TRASFERITI ALL'ESTERO?</strong>
                  </p>
                </td>
                <td>
                  <p>
                    I Dati saranno principalmente trattati all'interno del
                    territorio nazionale, ma potrebbero essere trasferiti anche
                    in altri Paesi presenti all’interno del territorio
                    dell’Unione Europea e/o Spazio Economico Europeo
                  </p>
                </td>
              </tr>
            </table>
            <p>
              L'eventuale trasferimento dei Dati dell'Interessato in Paesi
              situati al di fuori dell'Unione Europea avverrà unicamente nel
              rispetto di adeguate garanzie ai fini del trasferimento stesso (ed
              in particolare ottemperando a quanto previsto dagli artt. 45, 46 e
              49 del GDPR), ossia a condizione che la Commissione Europea abbia
              confermato che il Paese terzo dispone di un livello adeguato di
              protezione dei dati o esistano altre garanzie relativa alla
              protezione dei dati, quali norme vincolanti d'impresa o
              sottoscrizione di clausole contrattuali standard dell'UE.
            </p>
            <p>
              Hai il diritto di ottenere dal Titolare una copia dei dati
              detenuti all'estero e di ottenere informazioni circa il luogo in
              cui tali dati sono conservati facendone espressa richiesta da
              inviare utilizzando i contatti indicati nel presente documento.
            </p>
            <table>
              <tr>
                <td>
                  <p>
                    <strong>QUALI SONO I MIEI DIRITTI?</strong>
                  </p>
                </td>
                <td>
                  <p>
                    Contattando l’indirizzo e-mail del DPO e/o quello del
                    Titolare sopra riportati, potrai chiedere ad UGO in
                    qualunque momento:
                  </p>
                  <ul>
                    <li>
                      l’accesso ai Dati che ti riguardano, la rettifica dei Dati
                      inesatti, l’integrazione dei Dati incompleti, la loro
                      cancellazione (diritto all’oblio), la limitazione del
                      trattamento nei casi previsti dall’art. 18 GDPR;
                    </li>
                    <li>
                      di ricevere in un formato strutturato, di uso comune e
                      leggibile da dispositivo automatico i Dati che ti
                      riguardano nei casi previsti dall’art. 20 GDPR; nonché, se
                      tecnicamente fattibile, di trasmettere tali dati a un
                      altro titolare del trattamento senza impedimenti;
                    </li>
                    <li>
                      di revocare in qualsiasi momento il consenso prestato;
                      nonché oppormi in qualsiasi momento al trattamento dei
                      Dati ai sensi dell’art. 21 GDPR, dando evidenza delle
                      ragioni che giustifichino l’opposizione;
                    </li>
                    <li>
                      ogni altra richiesta di chiarimento in merito al
                      trattamento dei dati effettuato dal Titolare.
                    </li>
                  </ul>
                </td>
              </tr>
            </table>
            <p>
              Ti ricordiamo, infine, che è sempre possibile proporre reclamo
              alla competente Autorità di Controllo (Garante per la Protezione
              dei Dati Personali) ai sensi dell’art. 77 del GDPR, qualora
              ritenessi che il trattamento effettuato dal Titolare sia contrario
              alla normativa in vigore.
            </p>
            <p>
              Il Titolare del trattamento potrebbe apportare modifiche e/o
              integrazioni alla presente Privacy Policy, anche quale conseguenza
              di variazioni della normativa applicabile. Potrai visionare il
              testo della Privacy Policy costantemente aggiornata sul Sito nella
              sezione Privacy Policy o effettuare esplicita richiesta
              contattando direttamente il Titolare o il DPO ai recapiti sopra
              indicati.
            </p>
            <p>Ultimo aggiornamento: marzo ’22</p>
            <p>
              <strong> </strong>
            </p>
          </div>
        </Container>
      </section>
    </StyledGeneratedHtmlContainer>
  );
};

export default PrivacyPolicyPage;
